import React from 'react';


export const getId = (subjectName: string, layerId: string|number = "") => {
  if(layerId)
    return(subjectName+"-layer"+layerId)
  return(subjectName)
};

export function toggleLayer(elementId: string){
  /* toggle layer element */
  const layerElement = document.getElementById(elementId);
  if (layerElement === null) return;
  if (layerElement){
    layerElement.classList.toggle("transparentLayer");
    layerElement.classList.toggle("shadeElement");
  }
  /* toggle list element */
  const listElement = document.getElementById('list-item-'+elementId);
  if (listElement === null) return;
  if (listElement){
    listElement.classList.toggle("color-yellow");
    listElement.classList.toggle("color-white");
  }
}

export const scrollToElement = (elementId: string = "") => {
  const e = document.getElementById(elementId);
  if (e === null) return;
  if (e)
    e.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
}

/* not used

function toggleBox(elementId: any){
  const boxElement = document.getElementById('box-text'+elementId);
  if (boxElement === null) return;
  if (boxElement)
  boxElement.classList.toggle("hideElement");
}
 */
