import { IonMenuButton, IonButtons, IonIcon, IonImg, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FooterContainer from '../components/FooterContainer';

import './Home.scss';

interface ContainerProps {
  name: string;
}

const HomePage: React.FC<ContainerProps> = ({ name }) => {

  const [ loaded, setLoaded ] = useState<boolean>(false);
  const PreloadImage = (src: string) => {
    return (
      <IonImg src={src} onIonImgWillLoad={() => setLoaded(false)} onIonImgDidLoad={() => setLoaded(true)}
        className="preload">preload image</IonImg>
      )
  }

  return (
    <IonPage id="home-page">
      <IonContent>

        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>
            <Link className="pageTitle" to="/covid19">
                {name}
            </Link></IonTitle>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="covid19-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="covid19-image banner1" style={{'opacity': loaded === true ? '1' : undefined}}></div>
          <div className="covid19-image banner1-load" style={{'opacity': loaded === false ? '1' : undefined}}></div>
          {PreloadImage("/assets/img/covid19/banner-corona-5401250.jpg")}
        </div>


        <div>
          <div>
            <h1>
              pocketsono.de - Pocket Sono web app &nbsp;&nbsp;&nbsp;
              <Link className="pageTitle" to="/covid19"><IonIcon src="assets/icon/web-app.svg" size="large">Web App Icon</IonIcon></Link>
            </h1>
            coming up soon
          </div>
          {/*-- Footer without a border --*/}
          <FooterContainer />
        </div>
      </IonContent>

    </IonPage>
  );
};

export default HomePage;
