import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'; */

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import About from './pages/About';
import Covid19 from './pages/Covid19';
import Impressum from './pages/Impressum';
import HomePage from './pages/Home';

const HomePage2 = () => <div><h1>pocketsono.de 2</h1></div>


const App: React.FC = () => {


  return (
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId="main">
            {/* dummy test menu
            <div>
            <ul>
             <li><Link to='/'>Home 0</Link></li>
              <li><Link to='/home'>Home</Link></li>
              <li><Link to='/home2'>Home2</Link></li>
             <li><Link to='/about'>About</Link></li>
             <li><Link to='/impressum'>Impressum</Link>
                <Route path="/impressum" component={HomePage2} /></li>
             <li><Link to='/tabs'>Tabs</Link></li>
             <li><Link to='/covid19'>COVID19</Link></li>
             </ul>
             </div>
              */}

              <IonRouterOutlet id="main">
                <Route path="/home" exact={true} render={() => (
                    <HomePage name="demo-COVID19" />
                    )}
                />
                <Route path="/home2" component={HomePage2} />
                <Route path="/tabs" component={MainTabs} />
                <Route path="/about" component={About} />
                <Route path="/covid19" exact={true} render={() => (
                    <Covid19 name="demo-COVID19" />
                    )}
                />
                <Route path="/impressum" exact={true} render={() => (
                    <Impressum name="demo-COVID19" />
                    )}
                />
                <Redirect exact from="/" to="/home" />
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
  )
}

export default App;
