import { IonFooter, IonToolbar } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import './FooterContainer.css';

  const ImprintLink = () => (
    <small><strong>&copy; Copyright 2020 – Urheberrechtshinweis & Impressum</strong></small>
  );

  const FooterContainer = () => (
    <IonFooter className="ion-no-border">
        <IonToolbar>
        <br/><br/>
        <Link className="impressum" to="/impressum">
            <ImprintLink />
        </Link>

        </IonToolbar>
    </IonFooter>
  );

export default FooterContainer;
