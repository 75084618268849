import React, { lazy, Suspense, useState } from 'react';
import { IonList, IonItem, IonLabel, IonPopover, IonIcon, IonText } from '@ionic/react';
import { informationCircle, chevronDownOutline } from 'ionicons/icons';
import { subjectFeature } from '../models/Subject';
import { scrollToElement, getId, toggleLayer } from '../components/DynamicSVG';
import './SubjectContainer.css';
import '@ionic/react/css/utils.bundle.css';
//import SonoImage from './svg/10_Fragmentierte_Pleura_bei_COVID_19Stock.jsx';
//import { ReactComponent as SonoImage } from './svg/10_Fragmentierte_Pleura_bei_COVID_19Stock.svg'


	/* to do:

    PWA with
      max height: screen- x pixels for address/status bar
      proper Icons
      Website link to Install PWA (Lighthouse, Firebase)
        https://ionicframework.com/docs/react/pwa
        https://medium.com/@AnkitMaheshwariIn/build-a-progressive-web-app-pwa-with-ionic-4-ionic-framework-and-firebase-48183519c01
    landing page with
      short description
      links: 1) online 2) PWA install
      share button

    remove address bar on scrolling down -- see https://forum.ionicframework.com/t/ionic-in-web-hide-browser-address-bar/164970/21
    Dark Mode on/off
    svg extra (?) / aufräumen
    load-dummy picture while loading
      https://forum.ionicframework.com/t/resolved-ionimg-ion-img-ionimgdidload-event-not-firing-ionic-5/189889

    Hide elements in CSS - incl. performance comparison
      https://www.sitepoint.com/hide-elements-in-css/
	Fullscreen
		https://developers.google.com/web/fundamentals/native-hardware/fullscreen

	changelog:
    - create landing page and fix router/redirect implementation
    - outsourced hardcoded data to .json file
    - move to toplevel domain + enable SSL
    - add hint on rotating phone to landscape mode
    - fix issue of pictures not loaded on init w/o rotation by moving Subject Container from flex to block
          better: use VW learning as per css to define offset from left side
          https://stackoverflow.com/questions/49058890/how-to-get-a-react-components-size-height-width-before-render/49058984

    - add ionic-compliant router linking to fix Footer link FooterContainer
    - Impressum: cleanup
    - unique IDs and allow scrollTo for dynamically created elements
    - top-banner
    - add a menu for SubjectList (2x popover top + sticky)
		- allow multiple instances of SubjectContainer (data format to array in array)
		- dynamic id of svg (anchor for link)
		- align labels relative to image (not to div)
		- scale svg to max height OR width
		- capture orientation and scree size
		- moved from float to flex positioning
		- modularized, adding arrays. state variables and f() parameters
		- added functionality to change text when hover and vice versa
		- added toggle functionality
		- changed :hover to onMouseEnter/Leave()
		- added popover instead of box-text div
		- manipulate elements by JSX adding/removing class attributes
		- added in-line svg
	*/

const DisplaySvg = lazy(() => import('../components/DisplaySVG'));
const renderLoader = () => (
  <svg height="30" width="200">
    <text x="0" y="15">Loading SVG . . .</text>
  </svg>
);

interface ContainerProps {
  pageName: string;
  subjectName: string;
  subjectFeatures: Array<subjectFeature>;
}


const SubjectContainer: React.FC<ContainerProps> = (ContainerProps) => {
  const [showPopover, setShowPopover] = useState(false);
	const [popoverEvent, setPopoverEvent] = useState();
  const presentPopover = (e: any, elementTitle: any, elementText: any) => {
    setPopoverEvent(e.nativeEvent);
	  setContentPopover(elementText);
	  setTitlePopover(elementTitle);
    setShowPopover(true);
  };

  const [contentPopover, setContentPopover] = useState('');
  const [titlePopover, setTitlePopover] = useState('');

  function Box(props: any){
	  return(
	  <div className="box-text hideElement" id={"box-text"+props.id}>

		<strong>{props.title}</strong><br/>
		{props.text}
	  </div>
	  );
  }


  return (
    <div>
      <h3 className="ion-padding-top ion-padding-start" onClick={() => {scrollToElement(getId(ContainerProps.subjectName));}}>
        {ContainerProps.pageName}: {ContainerProps.subjectName}
  		  &nbsp;&nbsp;&nbsp;<IonIcon icon={chevronDownOutline}>Scroll Down Icon</IonIcon>
      </h3>


	<div className="subject-container">


	{/**/}


    <Suspense fallback={renderLoader()}>
      <DisplaySvg svgSubjectName={ContainerProps.subjectName} />
    </Suspense>

	<IonList className="feature-list ion-no-padding ion-no-margin bg-transparent">
	  {ContainerProps.subjectFeatures.map((subjectFeature) => (
		<IonItem lines="none" color="none" key={subjectFeature.id}>
		  <IonLabel
			onMouseEnter={() => {toggleLayer(getId(ContainerProps.subjectName,subjectFeature.id));} }
			onMouseLeave={() => {toggleLayer(getId(ContainerProps.subjectName,subjectFeature.id));} }
			><IonText id={"list-item-"+getId(ContainerProps.subjectName,subjectFeature.id)} className={"color-white"}><strong>{subjectFeature.title}</strong> </IonText>&nbsp;&nbsp;
		  </IonLabel>

  		{/* hide toggle icon
		  <IonIcon icon={toggle} slot="end" className={"color-white"}
			onClick={() => {toggleLayer(ContainerProps.subjectName+"layer"+subjectFeature.id);} }
		  />
      */}
		  <IonIcon icon={informationCircle} slot="end" className={"color-white"}
		    onClick={(e) => {presentPopover(e, subjectFeature.title, subjectFeature.text)} }
      >Information Icon</IonIcon>

		</IonItem>
	  ))}

	</IonList>

	<div className="feature-list">
    <IonIcon src="/assets/icon/rotating-phone-vertical.svg" size="large">Rotating Phone Icon</IonIcon>
  </div>
		{/**/}

	</div>

	  {ContainerProps.subjectFeatures.map((subjectFeature) => {
		return (
		  <Box key={subjectFeature.id} id={subjectFeature.id} title={subjectFeature.title} text={subjectFeature.text} />
		);
	  })}



      <IonPopover
        isOpen={showPopover}
        cssClass='popover'
        event={popoverEvent}
		showBackdrop={false}
		backdropDismiss={true}
		onDidDismiss={e => setShowPopover(false)}
      >
        <p><strong>{titlePopover}</strong><br/>{contentPopover}</p>
      </IonPopover>

	</div>
  );


};

export default SubjectContainer;
