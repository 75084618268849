import { IonButtons, IonMenuButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import FooterContainer from '../components/FooterContainer';
import './Covid19.scss';

interface ContainerProps {
  name: string;
}
const Impressum: React.FC<ContainerProps> = ({ name }) => {

  const ImprintText = () => (

	<small><h4>&copy; Copyright 2020 – Urheberrechtshinweis</h4>
	<p>Alle auf dieser Internetpräsenz verwendeten Texte, Fotos und grafischen Gestaltungen sind urheberrechtlich geschützt. Sollten Sie Teile hiervon verwenden wollen, wenden Sie sich bitte an den Seitenbetreiber. Er wird dann gegebenenfalls den Kontakt zum Urheber oder Nutzungsberechtigten herstellen.
	</p>

	<p>
	Bildrechte:<br />
	tbd<br />
	tbd<br />
	</p>
  <div>
    <p>
    Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik" target="_blank" rel="noopener noreferrer">Freepik</a>, <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware" target="_blank" rel="noopener noreferrer">Good Ware</a>, <a href="https://www.flaticon.com/authors/srip" title="srip" target="_blank" rel="noopener noreferrer">srip</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </p>
  </div>

	<p>Inhaltlich verantwortlich:<br />
	Dr. Peter Banholzer, <br />
	Marc Biehn
	</p>

  <h4>Impressum</h4>
	<p>
  Marc Biehn<br />
	Braystr. 3<br />
	81677 München
	</p>

	<p>
	Telefon: +491709423223<br />
	E-Mail: <a href="mailto:marc@biehn.info">marc@biehn.info</a>
	</p>
	</small>
  );
  return (

  <IonPage id="covid19-page">
    <IonContent>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
          <Link className="pageTitle" to="/home">
              {name}
          </Link></IonTitle>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className="covid19-header">
        {/* Instead of loading an image each time the select changes, use opacity to transition them */}
        <div className="covid19-image banner1 opacity" ></div>
      </div>
      <div className="covid19-info">
        <ImprintText />
        <FooterContainer />
      </div>
      </IonContent>
    </IonPage>
  );
};

export default Impressum;
