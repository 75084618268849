import { IonButtons, IonMenuButton, IonButton, IonIcon, IonPopover, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonLabel, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FooterContainer from '../components/FooterContainer';
import SubjectContainer from '../components/SubjectContainer';
import { scrollToElement } from '../components/DynamicSVG';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import { section, subjectList } from '../models/Subject';

import axios from 'axios';
import './Covid19.scss';


/*
const subjectFeatures1: subjectFeature[] = [
  {
    id: 2,
    title: 'fragmentierte Pleuralinie',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 3,
    title: 'verbreiterte B-Linien',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 4,
    title: 'Konsolidierungen',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  }
];
const subjectFeatures2: subjectFeature[] = [
  {
    id: 2,
    title: 'second example',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  }
];
const subjectList1: subjectList[] = [
  {
	  id: 1,
	  name: 'Subject #1',
	  features: subjectFeatures1,
	  file: 'drawing1.svg'
  },
  {
	  id: 2,
	  name: 'Subject #2',
	  features: subjectFeatures2,
	  file: 'drawing2.svg'
  }
];
*/

interface ContainerProps {
  name: string;
}

const Covid19: React.FC<ContainerProps> = ({ name }) => {

  const [showPopover, setShowPopover] = useState(false);
	const [popoverEvent, setPopoverEvent] = useState();
  const [location, setLocation] = useState<'banner1' | 'banner2'>('banner1');

  const selectOptions = {
    header: 'Select a banner'
  };

  const presentPopover = (e: React.MouseEvent | any ) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  const [mySections, setSections] = useState<any[]>([]);
  const DATA_URL = "../assets/data";

    useEffect(() => {
      const data = async () => {

        const url = DATA_URL + "/sections.json";
        try {
          const res = await axios.get(url);
          const sectionsData = res.data;
          if (sectionsData) {
            setSections(sectionsData);
            /*console.log(sectionsData);
            console.log(sectionsData[0].subjectlist); */
          }
        } catch (err) {
          throw new Error(err);
        }
      };
      data();
    }, []);


  const covidSection = mySections.map ((thisSection: section) => {
    if((thisSection.id as number) == 1) /* covid19 */
      return(
        thisSection.subjectlist.map ((thisSubjectList: subjectList) => {
          return(
            <SubjectContainer key={thisSection.id+"-"+thisSubjectList.id} pageName={thisSection.name} subjectName={thisSubjectList.name} subjectFeatures={thisSubjectList.features}/>

          )
        })
      )
    return(null)
  });
  	/* TODO: remove duplicate function: outsource + standardize ID generation

  function scrollToElement(elementId: any) {
    const e = document.getElementById(elementId);
    if (e === null) return;
    if (e)
      e.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
      });
  }
    */
  interface SubjectPopoverProps {
    dismiss: () => void;
  };

  const SubjectPopover: React.FC<SubjectPopoverProps> = ({dismiss}) => {

    const close = (url: string) => {
      window.open(url, '_blank');

      dismiss();
    };



    const subjectPopoverItems = mySections.map ((thisSection: section) => {
    if((thisSection.id as number) == 1) /* covid19 */
      return(
        thisSection.subjectlist.map ((thisSubjectList: subjectList) => {
          return(
            <IonItem button onClick={() => {scrollToElement(thisSubjectList.name);}} >
              <IonLabel>{thisSubjectList.name}</IonLabel>
            </IonItem>
          )
        })
      )
    return(null)
    });
    return (
      <IonList>
        {subjectPopoverItems}
        <IonItem button onClick={() => close('https://www.degum.de/nc/aktuelles/degum-ultraschall/im-detail/news/anleitung-zum-lungenultraschall-bei-verdacht-auf-covid-19.html')}>
          <IonLabel>DEGUM ultrasound proposal</IonLabel>
        </IonItem>
        <IonItem button onClick={dismiss}>
          <IonLabel>Support</IonLabel>
        </IonItem>
      </IonList >
    )
  }

  return (
    <IonPage id="covid19-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>
            <Link className="pageTitle" to="/home">
                {name}
            </Link></IonTitle>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}>Page Menu Button</IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="covid19-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="covid19-image banner1" style={{'opacity': location === 'banner1' ? '1' : undefined}}></div>
          <div className="covid19-image banner2" style={{'opacity': location === 'banner2' ? '1' : undefined}}></div>
        </div>


        <div className="covid19-info">

    	    {/*-- Generate SubjectContainer from SubjectList with all Features for this Section --*/}

    		  {covidSection}


          <h3 className="ion-padding-top ion-padding-start">Details</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Banner Style
              </IonLabel>
              <IonSelect value={location} interfaceOptions={selectOptions} onIonChange={(e) => setLocation(e.detail.value as any)}>
                <IonSelectOption value="banner1">Banner 1</IonSelectOption>
                <IonSelectOption value="banner2">Banner 2</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>

          {/*-- Footer without a border --*/}
          <FooterContainer />
          {/*-- Sticky Button --*/}
          <IonIcon onClick={presentPopover} className="stickyButton" slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}>Page Bottom Menu Icon</IonIcon>
        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <SubjectPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default Covid19;
